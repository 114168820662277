<template>
    <f-card>
        <v-row>
            <v-col cols="12">
                <f-card-title>Dados</f-card-title>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-text-field
                    v-model="language.language"
                    :disabled="false"
                    outlined
                    dense
                    hide-details="auto"
                    label="Nome" />
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="language.abbreviation"
                    :disabled="false"
                    outlined
                    dense
                    hide-details="auto"
                    label="Abreviação" />
            </v-col>
        </v-row>
    </f-card>
</template>

<script>
export default {
    computed: {
        language(){
            return this.$store.state.languages.view
        },
    },
}
</script>